<template>
  <div class="oa-login" v-loading="pageLoading"></div>
</template>

<script>
export default {
  data() {
    return {
      pageLoading: true,
    };
  },
  created() {
    const { username, password } = this.$route.query;
    if (username && password) {
      this.handleLogin(username, password);
    } else {
      this.$router.push("/login");
    }
  },
  methods: {
    handleLogin(username, password) {
      this.$store
        .dispatch("Login", {
          username,
          password,
          authType: "ldap",
          noNeedEncrypt2RSA: true,
        })
        .then((r) => {
          if (r.authToken) {
            this.$router.push({ path: this.redirect || "/" }).catch((err) => {
              console.log("跳转出错");
            });
          } else {
            this.$router.push("/login");
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.oa-login {
  height: 100%;
  width: 100%;
}
</style>